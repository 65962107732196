<template>
<div class="meback">
    <div class="metop">
        <van-uploader :after-read="afterImg">
        <div class="metop-img-div">
            <div class="king-div">
                <img class="king-img" src="@/assets/img/me/me_add.png"/>
                <img class="head" v-if="!data.model.head_url_org" src="@/assets/img/me/icon_head.png"/>
                <img class="head" v-if="data.model.head_url_org" :src="data.model.head_url_org"/>
            </div>
        </div>
        </van-uploader>
        <div class="metop-name-name">{{data.name}}</div>
    </div>

    <div class="set-bg">
        <div class="set-item">
            <van-field
                required
                label="真实姓名"
                style="border-radius:1rem"
                input-align="right"
                v-model="data.model.user_name"
                clearable
            />
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
            <van-field
                
                readonly=""
                label="所在城市"
                style="border-radius:1rem"
                input-align="right"
                v-model="data.address"
                @click="data.cityShow=true"
            />
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
            <van-field
                required
                label="手机号"
                style="border-radius:1rem"
                input-align="right"
                v-model="data.model.phone"
                clearable
            />
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
            <van-field
            
                label="微信号"
                style="border-radius:1rem"
                input-align="right"
                v-model="data.model.wechat_num"
                clearable
            />
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
            <van-field  label="微信二维码" input-align="right" label-align="center">
                <template #input>
                    <van-uploader v-model="fileList" :preview-full-image="false" :after-read="afterRead" :max-count="1"/>
                </template>
            </van-field>
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
            <van-field
                label="会员等级"
                style="border-radius:1rem"
                input-align="right"
                v-model="data.levelname"
                readonly="true"
            />
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
            <van-field
                label="注册时间"
                style="border-radius:1rem"
                input-align="right"
                v-model="data.model.create_time"
                readonly="true"
            />
        </div>
        <div class="set-item" style="margin-top:2rem;display: flex;">
            <van-button @click="back()" class="mt fa-btn" style="border:1px solid #C90000;background-color: #fff;color:#C90000;" size="large" round type="danger">取消</van-button>
			<van-button @click="saveMe()" class="mt fa-btn" style="margin-left:1rem;background-color:#C90000;" size="large" round type="danger">保存</van-button>

        </div>
        
    </div>
    
    <div class="service">
		<div class="service-title2">客云云科技 · 让经纪人生活更美好</div>
		<div class="service-title3">移动办公 合规平台 专业服务</div>
	</div>
    
    <!----------菜单开始------------->
    <Menu :emodel="menuIndex"></Menu>
    <!----------菜单结束------------->

    <div style="height:100px;"></div>


<van-action-sheet v-model:show="data.cityShow">
      <van-area
      @cancel="data.cityShow=false"
  title="所在城市"
  :area-list="areaList"
  :columns-placeholder="['请选择', '请选择', '请选择']"
  @confirm="confirmCity"
/>
</van-action-sheet>

</div>
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,nullStr,showMessage} from '../../common/utils.js'
import { areaList } from '@vant/area-data';
function back(){
    router.go(-1);
}
const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      uploadImg(file, 1);
};
const afterImg = (file) => {
      // 此时可以自行将文件上传至服务器
      uploadImg(file, 2);
};

const fileList = ref([
    ]);




    function uploadImg (file, index) {
      let param = new FormData(); //创建form对象
      param.append("file", file.file); //通过append向form对象添加数据
      console.log(param.get("file")); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      api.post("/upload/upload", param, config).then((res) => {
        if (res.errcode == "0") {
          if (index == 1) {
            data.model.wechat_org = URL.createObjectURL(file.file);
            // fileList[0].url=data.model.wechat_org;
            // fileList[0].beforeDelete=() => {
            //     data.model.wechat = "";
            // };
            data.model.wechat = res.path;
          }else if (index == 2) {
            data.model.head_url_org = URL.createObjectURL(file.file);
            data.model.head_url = res.path;
          }
          
        } else {
       
        }
      });
    }
let data=reactive({
    file:{},
    cityShow:false,
  name:localStorage.getItem("name"),
  img:nullStr(localStorage.getItem("img")),
  level:nullStr(localStorage.getItem("level")),
  levelname:nullStr(localStorage.getItem("levelname")),
  levelimg:nullStr(localStorage.getItem("levelimg")),
  levelup:nullStr(localStorage.getItem("levelup")),
  levelmap:JSON.parse(localStorage.getItem("levelmap")),
  model:{}
})



let route=useRoute()
let router=useRouter()
let menuIndex={index:4}

function confirmCity(val){
    /**
     * [{"code":"","name":""},null,null] MeEdit.vue:149
[{"code":"110000","name":"北京市"},{"code":"","name":""},null] MeEdit.vue:149
[{"code":"110000","name":"北京市"},{"code":"110100","name":"北京市"},{"code":"","name":""}] MeEdit.vue:149
[{"code":"110000","name":"北京市"},{"code":"110100","name":"北京市"},{"code":"110101","name":"东城区"}]
     */
    if(val[1]==null||val[2]==null){
        return;
    }
    if(isNull(val[2].name)){
        return;
    }
    data.model.province=val[0].name;
    data.model.city=val[1].name;
    data.model.area=val[2].name;
    data.address=nullStr(data.model.province)+"/"+nullStr(data.model.city)+"/"+nullStr(data.model.area);
    data.cityShow=false;
}

function saveMe(){
    if(isNull(data.model.user_name)){
        showMessage("请输入姓名");
        return;
    }
    // if(isNull(data.model.province)){
    //     showMessage("请选择省市区");
    //     return;
    // }
    if(isNull(data.model.phone)){
        showMessage("请输入手机号");
        return;
    }
    // if(isNull(data.model.wechat_num)){
    //     showMessage("请输入微信号");
    //     return;
    // }
    // if(isNull(data.model.wechat)){
    //     showMessage("请上传微信二维码");
    //     return;
    // }
    api.post("/web/user/userupdate",data.model).then((res)=>{
        if(res.errcode=="0"){
          showMessage("保存成功");
          localStorage.setItem("name",res.info.user_name);
          localStorage.setItem("img",res.info.head_url_org);
          back();
        }else{
          showMessage(res.errmsg);
        }
    })
}

function searchInfo(){
  
  api.post("/web/user/info",{}).then((res)=>{
        if(res.errcode=="0"){
          data.model=res.info;
          data.address=nullStr(data.model.province)+"/"+nullStr(data.model.city)+"/"+nullStr(data.model.area);
          if(data.model.wechat_org){
              fileList.value.push({"url":data.model.wechat_org});
          }
        }else{
          showMessage(res.errmsg);
        }
  })
  
}
searchInfo();

</script>
<style scoped lang="scss">
.set-bg{
    background: #ffffff;
    border-radius: 0.5rem;
    margin: 1rem;
    .set-sp{
        height:1px;
        background: #ebebeb;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .set-item{
        display:flex;
        align-items: center;
        .item-img{
            width:1.2rem;
        }
        .item-name{
            color:#061d37;
            margin-left:1rem;
        }
        .item-right{
            margin-left: auto;
            color:#9f9f9f;
        }
    }
}
.service{
  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }
  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.mecon-sp{
    margin:1rem;
    height:1px;
    background: #ebebeb;
}
.mecon{
    margin:1rem;
    display: flex;
    .meitem{
        padding:1rem;
        flex:1;
        text-align: center;
        img{
            width:100%;
        }
        div{
            margin-top:0.3rem;
            font-size:0.8rem;
        }
    }
}


.metop{
    margin: 1rem;
    text-align: center;
    .metop-img-div{
        position: relative;
        width:3rem;
        margin: auto;
        height: 3rem;
        border-radius: 50%;
        margin-top: 2rem;
        .king-div{
            position:absolute;
            margin-left: 2rem;
            
            .king-img{
                width:1rem;
                position: absolute;
                bottom: -0.1rem;
            }
            .head{
                width: 3rem;
                height:3rem;
                margin-left: -2rem;
                margin-top: -0.8rem;
                border-radius: 50%;
            }
        }
    }
    .metop-name{
        margin-left: 1rem;
        .metop-name-name{

        }
        .metop-name-lab{
            img{
                width:5rem;
            }
        }
    }
    .metop-set{
        margin-left:auto;
        img{
            width:1.5rem;
        }
    }
}
.meback{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    background: url('../../assets/img/me/me.png');
    background-size: 100% 100%;
}
</style>